import * as React from 'react'
import imageUrlBuilder from '@sanity/image-url'

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
}

const ImageWithCaptionSanity = ({ image, alt, caption }) => {
  const src = imageUrlBuilder(sanityConfig)
    .image(image)
    ?.auto('format')
    .url()

  // named groups: `width` and `height` from pattern `-123x456.jpg`
  const dimensions = src?.match(/-(?<width>\d+)x(?<height>\d+)\.(jpg|png)/).groups

  return (
    <figure className="text-center">
      {image && <img src={src} alt={alt} {...dimensions} loading="lazy" />}
      {caption && <figcaption className="text-light">{caption}</figcaption>}
    </figure>
  )
}

export default ImageWithCaptionSanity
