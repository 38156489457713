import * as React from 'react'
import newtab from 'utils/newtab'
import { GrDocumentPdf } from 'react-icons/gr'

const PDFLink = ({ title, file }) => {
  if (!file) return null

  /* eslint-disable no-unused-vars */
  const [type, id, ext] = file.asset._ref.split('-')
  const url = `https://cdn.sanity.io/files/${process.env.GATSBY_SANITY_PROJECT_ID}/${process.env.GATSBY_SANITY_DATASET}/${id}.${ext}`

  return (
    <p>
      <a className="link with-icon" href={url} {...newtab}>
        <GrDocumentPdf />
        {title}
      </a>
    </p>
  )
}

export default PDFLink
