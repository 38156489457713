import * as React from 'react'
import clsx from 'clsx'

const VideoEmbed = ({ title, url, className }) => (
  <section className={clsx(className, 'text-center richtext')}>
    {title && <h2 className="h2">{title}</h2>}
    <div className="container-sm aspect-ratio" style={{ '--aspect-ratio': 16/9 }}>
      <iframe src={url} title={title} loading="lazy" />
    </div>
  </section>
)

export default VideoEmbed
