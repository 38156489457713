import * as React from 'react'

const RawHtml = ({ content }) => (
  <div
    className="richtext"
    dangerouslySetInnerHTML={{ __html: content }}
  />
)

export default RawHtml
