import * as React from 'react'
import MarkdownIt from 'markdown-it'

const md = new MarkdownIt()

const Table = ({ rows }) => (
  <table className="no-scrollbar">
    <tbody>
      {rows.map(({ cells }, i) => (
        <tr key={i}>
          {cells.map((cell, j) => (
            <td dangerouslySetInnerHTML={{ __html: md.renderInline(cell) }} key={j} />
          ))}
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
