import * as React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import slugify from 'slugify'
import Cta from 'components/common/CTA'
import RawHtml from 'components/page-blocks/RawHtml'
import ImageWithCaptionSanity from 'components/common/ImageWithCaptionSanity'
import Markdown from 'components/page-blocks/Markdown'
import PDFLink from 'components/page-blocks/PDFLink'
import Table from 'components/page-blocks/Table'
import VideoEmbed from 'components/page-blocks/VideoEmbed'
import clsx from 'clsx'

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
}

const serializers = {
  types: {
    block: props => {
      const { style } = props.node

      switch (style) {
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'h5':
        case 'h6':
          return React.createElement(style, {
            className: style,
            id: slugify(props.node.children[0].text, { lower: true })
          }, props.children)

        default:
          return BlockContent.defaultSerializers.types.block(props)
      }
    },

    cta: ({ node }) => <p><Cta {...node} /></p>,

    html: ({ node }) => <RawHtml {...node} />,

    hr: () => <hr className="lg" />,

    imageWithCaption: ({ node }) => <ImageWithCaptionSanity {...node} />,

    markdownBlock: ({ node }) => <Markdown {...node} />,

    pdfLink: ({ node }) => <PDFLink {...node} />,

    table: ({ node }) => <Table {...node} />,

    videoEmbed: ({ node }) => <VideoEmbed {...node} />,
  },

  marks: {
    link: ({ mark, children }) => {
      const external = mark.href.startsWith('http')
        ? { target: '_blank', rel: 'noopener' }
        : {}
      return <a href={mark.href} className="link" {...external}>{children}</a>
    },

    'text-center': ({ mark, children }) => <span className={mark} style={{ display: 'block' }}>{children}</span>
  },
}

const SanityBlock = ({ body, className }) => !!body && (
  <BlockContent {...sanityConfig}
    blocks={body}
    serializers={serializers}
    className={clsx('richtext', className)}
    renderContainerOnSingleChild
  />
)

export default SanityBlock
