import * as React from 'react'
import MarkdownIt from 'markdown-it'

const md = new MarkdownIt()

const Markdown = ({ content }) => (
  <div
    className="richtext"
    dangerouslySetInnerHTML={{ __html: md.render(content) }}
  />
)

export default Markdown
